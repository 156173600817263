import { Injectable, OnInit } from '@angular/core';
import { WebService } from './web.service';
import * as saveAs from 'file-saver';
import { BehaviorSubject, Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmployeeProfileService {
lang:any = 'en'
// private triggerEvent = new Subject<void>();
// trigger$ = this.triggerEvent.asObservable();
 

  sharedDocs: any;
  private EmployeesSource = new BehaviorSubject<any>([]);
  private printDataSource = new BehaviorSubject<any>([]);
  private emp_docData = new BehaviorSubject<any>([]);
  private com_img = new BehaviorSubject<any>(null);
  private printSalaryCertificate = new BehaviorSubject<any>([]);
  private setCom_details = new BehaviorSubject<any>([]);
  private emp_approved_details = new BehaviorSubject<any>([]);
  private listingRoute = new BehaviorSubject<any>([]);
  private no_of_employees = new BehaviorSubject<any>([]);
  getNoOfemployee$ = this.no_of_employees.asObservable();
  listingRoute$ = this.listingRoute.asObservable();
  employeeExportedDocs = this.EmployeesSource.asObservable();
  exportedPrintDocs = this.printDataSource.asObservable();
  com_logo$ = this.com_img.asObservable();
  exportedSalaryCertificate = this.printSalaryCertificate.asObservable();
  getComDetails = this.setCom_details.asObservable();
  empDocDetails = this.emp_docData.asObservable();
  empApprvDetails$ = this.emp_approved_details.asObservable();
  private fullnfinal = new BehaviorSubject<any>([]);
  fullnfinalData$ = this.fullnfinal.asObservable();


  constructor(private webService: WebService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private http: HttpClient,    
    ) { 

    }

    // triggerAction() {
    //   this.triggerEvent.next();
    // }
  setFullnFinal(data:any){
      this.fullnfinal.next(data)
  }
  setNoOfEmployee(emp:any){
    this.no_of_employees.next(emp);
  }
  setComLogo(img:any){
  this.com_img.next(img);
  }
  setListingRoute(route:any){
  this.listingRoute.next(route);
  }
  setDocData(img:any){
  this.emp_docData.next(img);
  }
  setEmpApprvDetails(img:any){
  this.emp_approved_details.next(img);
  }
  setDocs(docs: any) {
    this.EmployeesSource.next(docs);
  }
  async downloadFile(url:string,fileName:string, payload?:Object) {
    try {
      this.spinner.show()
   const response = await this.webService.simpleGet('company/' + url, payload).toPromise().then((response:any)=>{
        console.log(response.headers.keys())
        console.log(response)
        if(response.status !== 200 || response.body.type == 'application/json'){
          throw {message:'Something went wrong. Please try again later'};
        }
        saveAs(response.body, fileName);
        this.spinner.hide()
      },(err) =>{
        throw err
      });
    } catch (err:any) {
      this.spinner.hide()
      // this.toastr.error(err.message)
      throw err 
    }
  }
  setPrintDoc(docs: any) {
    this.printDataSource.next(docs);
  }
  setSalaryCerti(docs: any) {
    this.printSalaryCertificate.next(docs);
  }
  setComDetails(docs: any) {
    this.setCom_details.next(docs);
  }
  updateCompanyDetails(payload: any) {
    return this.webService.postFormData(
      'company/update-company-details',
      payload
    );
  }

  getYrHolidayList(payload: any){
    return this.webService.post('company/list-of-publish-active-holiday-template', payload);
  }
  
   // performance management//

   addobjectivegoal(payload: any) {
    return this.webService.post('company/add_objectivegoal', payload);
  }
  
  
  // get objective goals//
  
  getobjectivegoal(payload:any) {
    return this.webService.post('company/get_object',payload);
  }

  deletekeyresults(payload:any){
    return this.webService.post('company/delete_keyresult', payload);
  }

  // edit key result progress//

editkeyresult(payload:any) {
	return this.webService.post('company/editkeyresultprogress',payload);
}

addmultiplekeyresults(payload: any) {
	return this.webService.post('company/add_multiplekeys', payload);
}


  createGratuityRule(payload: any) {
    return this.webService.post('company/create_gratuity', payload);
  }

  fetchGratuityRules(payload: any) {
    return this.webService.post('company/get_gratuity_rule', payload);
  }

  deleteGratuityRule(payload: any) {
    return this.webService.post('company/delete_gratuity_rule', payload);
  }

  updateGratuityRule(payload: any) {
    return this.webService.post('company/update_gratuity_rule', payload);
  }

 
  fetchStates() {
    return this.webService.post('company/get_state_list', {
      countrycode: 'AE',
    });
  }

  
  createDispensary(payload: any) {
    return this.webService.post('company/add-dispensary', payload);
  }
  fetchEmployees(payload: any) {
    return this.webService.postFormData('company/get-employee', payload);
  }
  fetchDispensaries(payload: any) {
    return this.webService.post('company/get-dispensary', payload);
  }
  getEmployeeDetails(payload: any) {
    return this.webService.post('company/get-employee-details', payload);
  }
  getCompanyDetails() {
    return this.webService.post('company/get-company-data',{});
  }
  getRivisionDetails(payload: any) {
    return this.webService.post('company/employee-revision-arrear-history-report', payload);
  }
  singleViewImage(payload: any) {
    return this.webService.post('company/single-view-image', payload);
  }
  getEmployeeDetailsPublic(payload: any) {
    return this.webService.post('public/get-employee-details', payload);
  }

  getEmployeeMaster() {
    return this.webService.post('company/get-employee-master', {});
  }

  // OnBoard Methods.

  createOnBoardEmployee(payload: any) {
    return this.webService.postFormData('company/add-onboard-employee', payload);
  }

  updateOnboardEmployee(payload: any) {
    return this.webService.postFormData(
      'company/add-onboard-employee',
      payload
    );
  }

  generateOfferLetter(payload: any) {
    return this.webService.postFormData('company/offer-letter-generation', payload);
  }

  generateOnBoardAcceptanceLink(payload:any){
    return this.webService.post('company/generate-employee-onboard-acceptance-link', payload)
  }

  checkOnboardAcceptanceLink(payload:any){
    return this.webService.post('public/check-onboard-acceptance-link', payload)
  }

  acceptOfferLetter(payload:any){
    return this.webService.post('public/change-status-of-onboard-employee', payload)
  }

  sendOfferLetter(payload:any){
    return this.webService.post('company/send-offer-letter', payload)
  }

  getAcceptanceData(payload:any){
    return this.webService.post('company/get-onboard-employee', payload)
  }

  
  getOnboardEmployeeList(payload:any){
    return this.webService.post('company/get-onboard-employee-list', payload)
  }

  getPendingOnboardEmployeeList(payload:any){
    return this.webService.post('company/get-pending-onboard-employee', payload)
  }

 


  // OnBoard Method End.

  createEmployee(payload: any) {
    return this.webService.postFormData('company/add-employee', payload);
  }

  approveOnboardEmployee(payload: any) {
    return this.webService.postFormData(
      'company/approve-onboard-employee',
      payload
    );
  }

  updateEmployee(payload: any) {
    return this.webService.postFormData(
      'company/update-employee-details',
      payload
    );
  }

  deleteEmployee(payload:any){
    return this.webService.post('company/delete-employee', payload); 
  }
  updateEmployeeAddress(payload: any) {
    return this.webService.postFormData(
      'company/update-employee-address',
      payload
    );
  }

  updateEmployeeBankDetails(payload: any) {
    return this.webService.postFormData(
      'company/update-employee-bank',
      payload
    );
  }

  updateEmployeeFamilyDetails(payload: any) {
    return this.webService.postFormData(
      'company/update-employee-fam-member',
      payload
    );
  }

  updateEmployeeTrainingDetails(payload: any) {
    return this.webService.postFormData(
      'company/update-employee-training',
      payload
    );
  }
  updateEmployeeTrainingDetailsPublic(payload: any) {
    return this.webService.postFormData(
      'public/update-employee-training',
      payload
    );
  }

  updateEmployeeOtherDetails(payload: any) {
    return this.webService.postFormData(
      'company/update-employee-other-details',
      payload
    );
  }

  updateEmployeeDisciplinaryDetails(payload: any) {
    return this.webService.postFormData(
      'company/update-employee-disciplinary-details',
      payload
    );
  }

  updateEmployeeContractDetails(payload: any) {
    return this.webService.postFormData(
      'company/update-employee-contract',
      payload
    );
  }

  updateEmployeeAccidentDetails(payload: any) {
    return this.webService.postFormData(
      'company/update-employee-accident-details',
      payload
    );
  }

  updateEmployeeAccidentDetailsPublic(payload: any) {
    return this.webService.postFormData(
      'public/update-employee-accident-details',
      payload
    );
  }

  updateEmployeeExtraCurriculumDetails(payload: any) {
    return this.webService.postFormData(
      'company/update-employee-extra-curricular',
      payload
    );
  }
  updateEmployeeExtraCurriculumDetailsPublic(payload: any) {
    return this.webService.postFormData(
      'public/update-employee-extra-curricular',
      payload
    );
  }

  updateEmployeeEducationDetails(payload: any) {
    return this.webService.postFormData(
      'company/update-employee-education',
      payload
    );
  }

  updateEmployeeEducationDetailsPublic(payload: any) {
    return this.webService.postFormData(
      'public/update-employee-education',
      payload
    );
  }

  updateEmployeeEmploymentDetails(payload: any) {
    return this.webService.postFormData(
      'company/update-employee-pf-esic',
      payload
    );
    return this.webService.postFormData(
      'company/update-employee-employment',
      payload
    );
  }

  updateEmployeeHrDetails(payload: any) {
    return this.webService.postFormData(
      'company/update-employee-hr-details',
      payload
    );
  }

  updateEmployeeAnnualCompDetails(payload: any) {
    return this.webService.postFormData(
      'company/update-earning-amount',
      payload
    );
  }

  updateEmployeeFullFinalDetails(payload: any) {
    return this.webService.postFormData(
      'company/update-employee-full-and-final-details',
      payload
    );
  }

  changeEmployeeStatus(payload: any) {
    return this.webService.post('company/update-employee-status', payload);
  }

  approveEmployee(payload: any) {
    return this.webService.post('company/activate-new-employee', payload);
  }

  changeEmployeePackage(payload: any) {
    return this.webService.post('company/employee_bulk_update', payload);
  }

  changeEmployeeSalaryTemplate(payload: any) {
    return this.webService.postFormData(
      'company/employee_bulk_update',
      payload
    );
  }

  generateEmployeeLetterWrtingTemplate(payload: any) {
    return this.webService.postFormData(
      'company/letter-writting-report',
      payload
    );
  }

  
  fetchEmployeePackageMaster() {
    return this.webService.post('company/employee-package-master', {});
  }

  createEmployeePackage(payload: any) {
    return this.webService.post('company/add-employee-package', payload);
  }

  fetchEmployeePackages(payload: any) {
    return this.webService.post('company/employee-package', payload);
  }

  deleteEmployeePackage(payload: any) {
    return this.webService.post('company/add-employee-package', payload);
  }

  updateEmployeePackage(payload: any) {
    return this.webService.post('company/update-employee-package', payload);
  }

  addSalaryHead(payload: any) {
    return this.webService.post('company/add-salary-template-head', payload);
  }

  fetchSalaryHeads() {
    return this.webService.post('company/get-salary-template-head', {});
  }

  addSalaryTemplate(payload: any) {
    return this.webService.postFormData('company/add-salary-template', payload);
  }

  updateSalaryTemplate(payload: any) {
    return this.webService.postFormData(
      'company/update-salary-template',
      payload
    );
  }

  fetchSalaryTemplates(payload: any) {
    return this.webService.post('company/get-salary-template', payload);
  }

  updateEmployeeAssetDetails(payload: any) {
    return this.webService.post('company/update-employee-assets', payload);
  }

  createPaySlipTemplate(payload: any) {
    return this.webService.postFormData('company/create_payslip_temp', payload);
  }

  fetchPaySlipTemplates(payload: any) {
    return this.webService.post('company/get_payslip_temp', payload);
  }

  updatePaySlipTemplate(payload: any) {
    return this.webService.postFormData('company/update_payslip_temp', payload);
  }

  deletePaySlipTemplate(payload: any) {
    return this.webService.postFormData('company/delete_payslip_temp', payload);
  }

  fetchPaySlipTemplateLibrary(payload: any) {
    return this.webService.post('company/get_payslip_temp_library', payload);
  }

  createBonusSlipTemplate(payload: any) {
    return this.webService.postFormData(
      'company/create_bonus_slip_temp',
      payload
    );
  }

  calculateSalary(payload: any) {
    let endpoint: any = '';
    let finalpayload: any = '';

    if (payload?.calculate_type == 'ctctogross') {
      endpoint = 'company/calculate-salary-range';

      finalpayload = {
        salary_template: payload.salary_template,
        state: payload.state,
        from_amount: payload.from_amount,
        to_amount: payload.to_amount,
        amount_span: payload.amount_span,
      };
    } else {
      endpoint = 'company/calculate-salary';

      finalpayload = {
        calculate_type: 'grosstoctc',
        salary_template: payload.salary_template,
        amount: payload.amount,
        state: payload.state,
      };
    }

    return this.webService.postFormData(endpoint, finalpayload);
  }

  uploadCompanyLogo(payload: any) {
    return this.webService.postFormData('company/update-company-logo', payload);
  }

  
  submitFullandFinal(payload: any) {
    return this.webService.post('company/update-employee-full-and-final-details', payload);
  }

  updateShiftEmployee(payload: any) {
    switch (payload.editOptionConfig) {
      case 'multiple':
        return this.webService.postFormData(
          'company/update-employee-shift',
          payload
        );
        break;

      default:
        return this.webService.postFormData(
          'company/emp-update-shift-details',
          payload
        );
        break;
    }
  }

  fetchShiftDetails(payload: any) {
    return this.webService.post('company/shift-details', payload);
  }
  exportShiftDetails(payload: any) {
    return this.webService.post('company/shift-details', payload);
  }

  updateShiftRate(payload: any) {
    let pload: any = {};

    switch (payload.editOptionConfig) {
      case 'multiple':
        // pload = {
        //   'shift_rate': payload.shift_rate,
        //   'row_checked_all': payload.row_checked_all,
        //   'checked_row_ids': payload.checked_row_ids,
        //   'unchecked_row_ids': payload.unchecked_row_ids,
        // }

        return this.webService.postFormData(
          'company/update-employee-shift-rate',
          payload
        );
        break;

      default:
        // pload = {
        //   'shift_rate': payload.shift_rate,
        //   'emp_id': payload.emp_id,
        // }

        return this.webService.postFormData(
          'company/emp-update-shift-rate',
          payload
        );
        break;
    }
  }

  importEmployeeData(payload: any) {
    return this.webService.postFormData(
      'company/import-employee-data',
      payload
    );
  }

  importEmployeeDataCsv(payload: any) {
    return this.webService.postFormData(
      'company/import-employee-csv-data-to-temp',
      payload
    );
  }

  exportSampleEmployeeFile(payload: any) {
    return this.webService.post('company/export-sample-employee-file', payload);
  }

  exportEmployeeData(payload: any) {
    return this.webService.postFormData(
      'company/export-employee-list',
      payload
    );
  }

  exportEmployeeExtraData(payload: any) {
    return this.webService.postFormData(
      'company/export-employee-extra-data',
      payload
    );
  }


  getUnapprovedEmployees(payload: any) {
    return this.webService.postFormData(
      'company/get-unapproved-employees',
      payload
    );
  }

  submitEmployeeBulkApprove(payload: any) {
    return this.webService.postFormData(
      'company/employee-bulk-approve',
      payload
    );
  }

  getCreditSettingsValue() {
    return this.webService.post('company/get-credit-setting-value', {});
  }

  getCreditPurchaseOrderID(payload: any) {
    return this.webService.post('company/get-order-id', payload);
  }
  getApplyReimbursement (payload: any) {
    return this.webService.post('company/get-apply-reimbursement', payload);
  }
  addEmployeeReimbursement(payload: any) {
    return this.webService.post('company/add-reimbursement', payload);
  }
  updateEmployeeReimbursement(payload: any) {
    return this.webService.post('company/update-reimbursement', payload);
  }

  verifyCreditPurchasePayment(payload: any) {
    return this.webService.post('company/verify-order-id', payload);
  }

  verifyCreditPurchaseCoupon(payload: any) {
    return this.webService.post('company/check-coupon-code', payload);
  }

  fetchCreditPurchaseTransactionHistory(payload: any) {
    return this.webService.post('company/get-payment-history', payload);
  }

  createLetterWritingTemplate(payload: any) {
    return this.webService.post('company/create-letter-template', payload);
  }
  createEmailWritingTemplate(payload: any) {
    return this.webService.post('company/create-email-template', payload);
  }

  fetchLetterWritingTemplates(payload: any) {
    return this.webService.post('company/get-letter-template', payload);
  }
  fetchEmailWritingTemplates(payload: any) {
    return this.webService.post('company/get-email-template', payload);
  }

  updateLetterWritingTemplate(payload: any) {
    return this.webService.post('company/update-letter-template', payload);
  }
  updateEmailWritingTemplate(payload: any) {
    return this.webService.post('company/update-email-template', payload);
  }

  changeLetterWritingTemplateStatus(payload: any) {
    return this.webService.post(
      'company/update-letter-template-status',
      payload
    );
  }
  changeEmailWritingTemplateStatus(payload: any) {
    return this.webService.post(
      'company/update-email-template-status',
      payload
    );
  }

  deleteLetterWritingTemplate(payload: any) {
    return this.webService.post('company/delete-letter-template', payload);
  }
  deleteEmailWritingTemplate(payload: any) {
    return this.webService.post('company/delete-email-template', payload);
  }

  createBankSheet(payload: any) {
    return this.webService.postFormData('company/add-bank-sheet', payload);
  }

  updateBankSheet(payload: any) {
    return this.webService.postFormData('company/update-bank-sheet', payload);
  }

  fetchBankSheets(payload: any) {
    return this.webService.post('company/get-bank-sheet-list', payload);
  }

  deleteBankSheet(payload: any) {
    return this.webService.post('company/delete-bank-sheet', payload);
  }

  
  getSampleEmpExcelImportCsv(payload: any) {
    return this.webService.postFormData(
      'company/export-excel-sample-file',
      payload
    );
  }

  getEarningSheetEmployeeList(payload: any) {
    return this.webService.postFormData(
      'company/generate-earning-sheet-employee-list',
      payload
    );
  }

  generateEmployeeEarningSheet(payload: any) {
    return this.webService.postFormData(
      'company/generate-earning-sheet',
      payload
    );
  }

  getIncentiveForm(payload: any) {
    return this.webService.post('company/get-incentive-form', payload);
  }

  updateEmpIncentive(payload: any) {
    return this.webService.postFormData(
      'company/update-incentive-data',
      payload
    );
  }

  getEmployeeExtraEarnings(payload: any) {
    return this.webService.postFormData('company/get-extra-earning', payload);
  }
  pendingEmployeeExtraEarnings(payload: any) {
    return this.webService.post('company/get-extra-earning-pending-data', payload);
  }
  getReimbursement (payload: any) {
    return this.webService.post('company/get-reimbursement', payload);
  }
  geTapprovealReimbursement (payload: any) {
    return this.webService.post('company/get-reimbursement-pending-data', payload);
  }

  getpendingTDS (payload: any) {
    return this.webService.post('company/pending-declaration-list', payload);
  }

  geTDSList (payload: any) {
    return this.webService.post('company/get-tds-list', payload);
  }
  geTDSLibrary (payload: any) {
    return this.webService.post('company/get-tds-library-list', payload);
  }

  getextraEarning (payload: any) {
    return this.webService.post('company/get-extra-earning', payload);
  }
  getReimbursementReport (payload: any) {
    return this.webService.post('company/extra-earning-report', payload);
  }
 



  pendingEmployeeAdvance(payload: any) {
    return this.webService.post('company/get-advance-data', payload);
  }

  updateEmployeeExtraEarning(payload: any, action: any) {
    let endpoint: any = '';
    switch (action) {
      case 'new':
        endpoint = 'add-extra-earning-data';
        break;

      case 'edit':
        endpoint = 'update-extra-earning-data';
        break;
    }

    return this.webService.postFormData('company/' + endpoint, payload);
  }

  getExtraEarningHeads(payload: any) {
    return this.webService.postFormData(
      'company/get-extra-earning-head',
      payload
    );
  }

  createExtraEarningHead(payload: any) {
    return this.webService.postFormData(
      'company/add-extra-earning-head',
      payload
    );
  }

  createAdvanceData(payload: any) {
    return this.webService.postFormData('company/add-advance-data', payload);
  }

  updateAdvanceData(payload: any) {
    return this.webService.postFormData('company/update-advance-data', payload);
  }

  getAdvanceData(payload: any) {
    return this.webService.postFormData('company/get-advance-data', payload);
  }

  importAdvanceData(payload: any) {
    return this.webService.postFormData('company/import-advance-data', payload);
  }

  getSampleAdvanceImportCsv(payload: any) {
    return this.webService.postFormData('company/export-advance-data', payload);
  }

  createSalarySheetTemplate(payload: any) {
    return this.webService.postFormData(
      'company/create-earning-sheet-template',
      payload
    );
  }

  createEmployeeSheetTemplate(payload: any) {
    return this.webService.postFormData(
      'company/create-employee-sheet-template',
      payload
    );
  }

  fetchSalarySheetTemplates(payload: any) {
    return this.webService.postFormData(
      'company/get-earning-sheet-template',
      payload
    );
  }

  fetchEmployeeSheetTemplates(payload: any) {
    return this.webService.postFormData(
      'company/get-employee-sheet-template',
      payload
    );
  }

  employeeSalaryHold(payload: any) {
    return this.webService.post('company/hold-employee-salary', payload);
  }

  removeEmployeeSalaryHold(payload: any) {
    return this.webService.postFormData(
      'company/remove-from-hold-salary-list',
      payload
    );
  }

  fetchEmployeeSalaryHold(payload: any) {
    return this.webService.post('company/get-hold-salary-employee', payload);
  }

  getMasterSheet(payload: any) {
    return this.webService.postFormData(
      'company/get-master-sheet-data',
      payload
    );
  }

  exportMasterSheet(payload: any) {
    return this.webService.postFormData(
      'company/export-master-sheet-data',
      payload
    );
  }

  getRevisionEmpList(payload: any) {
    return this.webService.postFormData(
      'company/get-revision-emp-list',
      payload
    );
  }
  getRevisionHistory(payload: any) {
    return this.webService.post(
      'company/employee-revision-arrear-history-log-report',
      payload
    );
  }
  updateRevisionEmpData(payload: any) {
    return this.webService.postFormData(
      'company/update-revision-emp-data',
      payload
    );
  }

  updateRevisionEmpMultipleData(payload: any) {
    return this.webService.postFormData(
      'company/update-checked-revision-emp-data',
      payload
    );
  }

  getRevisionEmpLog(payload: any) {
    return this.webService.post('company/get-revision-log', payload);
  }

  getFilteredRevisionEmpList(payload: any) {
    return this.webService.post(
      'company/get-filtered-revision-emp-list',
      payload
    );
  }

  runRevisionEmpPayroll(payload: any, runType: any) {
    if (runType == 'attendance') {
      //return this.webService.postFormData('company/calculate-attendance-data', payload);
    } else {
      //return this.webService.postFormData('company/run-revision-payroll', payload);
    }
    return this.webService.postFormData(
      'company/create-revision-schedule',
      payload
    );
  }

  getRevisionMasterReport(payload: any) {
    if (payload.report_type == 'consolidated') {
      //return this.webService.postFormData('company/get-total-revision-master-report', payload);
      return this.webService.postFormData(
        'company/get-revision-master-report',
        payload
      );
    } else {
      return this.webService.postFormData(
        'company/get-revision-master-report',
        payload
      );
    }
  }

  exportInstructionReport(payload: any) {
    return this.webService.postFormData(
      'company/generate-instruction-report',
      payload
    );
  }

  getInstructionReportData(payload: any) {
    return this.webService.post('company/get-bank-payment-data', payload);
  }

  confirmInstructionReportData(payload: any) {
    return this.webService.post('company/confirm-bank-payment', payload);
  }

  deleteInstructionReportData(payload: any) {
    return this.webService.post(
      'company/delete-bank-payment-ref-file',
      payload
    );
  }

  generateBankInstruction(payload: any) {
    return this.webService.post(
      'company/generate-bank-instruction',
      payload
    );
  }
  generateRivisionInstruction(payload: any) {
    return this.webService.postFormData(
      'company/genertate-bank-instruction',
      payload
    );
  }
  generateRivisionInstructionNew(payload: any) {
    return this.webService.postFormData(
      'company/revision-genertate-bank-instruction',
      payload
    );
  }
  generateChallanReport(payload: any, challanType: string) {
    switch (challanType) {
      case 'esic':
        return this.webService.postFormData(
          'company/generate-esic-report',
          payload
        );
        break;

      default:
        return this.webService.postFormData(
          'company/generate-pf-report',
          payload
        );
        break;
    }
  }

  getChallanReportData(payload: any, challanType: string) {
    switch (challanType) {
      case 'esic':
        return this.webService.post('company/get-esic-challan-data', payload);
        break;

      default:
        return this.webService.post('company/get-challan-data', payload);
        break;
    }
  }

  confirmChallanReportData(payload: any, challanType: string) {
    switch (challanType) {
      case 'esic':
        return this.webService.post(
          'company/confirm-esic-challan-payment',
          payload
        );
        break;

      default:
        return this.webService.post('company/confirm-challan-payment', payload);
        break;
    }
  }

  deleteChallanReportData(payload: any, challanType: string) {
    switch (challanType) {
      case 'esic':
        return this.webService.post(
          'company/delete-esic-challan-ref-file',
          payload
        );
        break;

      default:
        return this.webService.post('company/delete-challan-ref-file', payload);
        break;
    }
  }

  getChallanFormData(payload: any, challanType: string) {
    switch (challanType) {
      case 'esic':
        return this.webService.post(
          'company/get-challan-form-data',
          payload
        );
        break;

      default:
        return this.webService.post('company/get-challan-form-data', payload);
        break;
    }
  }

  submitConfirmChallanPayment(payload: any, challanType: string) {
    switch (challanType) {
      case 'esic':
        return this.webService.postFormData(
          'company/confirm-esic-challan-payment',
          payload
        );
        break;

      default:
        return this.webService.postFormData(
          'company/confirm-pf-challan-payment',
          payload
        );
        break;
    }
  }

  getEmployeeSalaryDetails(payload: any) {
    return this.webService.post('company/get-employee-salary-temp', payload);
  }

  getEmployeePackageDetails(payload: any) {
    return this.webService.post('company/get-employee-package-data', payload);
  }

  runSalarySheet(payload: any) {
    return this.webService.postFormData('company/run-salary-sheet', payload);
  }

  getSalarySheet(payload: any) {
    return this.webService.postFormData(
      'company/get-salary-sheet-data',
      payload
    );
  }
  getSalarySheetDetails(payload: any) {
    return this.webService.postFormData(
      'company/get-salary-sheet-data-details',
      payload
    );
  }
  getSalaryReport(payload: any) {
    return this.webService.postFormData(
      'company/get-calculated-revision-list',
      payload
    );
  }
  getArrearReport(payload: any) {
    return this.webService.post(
      'company/employee-arrear-slip-report',
      payload
    );
  }
  runBonusSheet(payload: any) {
    return this.webService.postFormData('company/run-bonus-sheet', payload);
  }

  getBonusSheet(payload: any) {
    return this.webService.post('company/get-bonus-sheet', payload);
  }

  uploadAttendanceFunnelMapFile(payload: any) {
    return this.webService.postFormData(
      'company/attendance-funnel/upload-map-file',
      payload
    );
  }

  uploadAttendanceFunnelCsv(payload: any) {
    return this.webService.postFormData(
      'company/attendance-funnel/upload-attendance',
      payload
    );
  }

  submitAttendanceFunnelEmployeeMapping(payload: any) {
    return this.webService.post(
      'company/attendance-funnel/map-employee',
      payload
    );
  }

  runIncentiveSheet(payload: any) {
    return this.webService.postFormData('company/run-incentive-sheet', payload);
  }

  getIncentiveSheet(payload: any) {
    return this.webService.postFormData('company/get-incentive-sheet', payload);
  }

  getIncentiveSheetDateRange(payload: any) {
    return this.webService.postFormData(
      'company/get-incentive-report-listing',
      payload
    );
  }

  runSupplementSalary(payload: any) {
    return this.webService.post('company/run-supplement-salary-sheet', payload);
  }


  getsalaryHeads(payload: any = '') {
    return this.webService.post('company/get-salary-template-head', payload);
  }
  getsalarygovHeads(payload: any = '') {
    return this.webService.post('company/get-earning-temp-head', payload);
  }
  savetdsTemplate(payload: any = '') {
    return this.webService.post('company/add-tds-template-data', payload);
  }
  getTDSAct(payload: any = '') {
    return this.webService.post('get-tds-act', payload);
  }
  gettdsTemplate(payload: any = '') {
    return this.webService.post('company/get-tds-data', payload);
  }
  getLeaveEmp(payload: any) {
    return this.webService.post('company/leave/get-employee', payload);
  }
  getshiftEarning(payload: any) {
    return this.webService.post('company/shift-earning-report', payload);
  }
  getshiftDuty(payload: any) {
    return this.webService.post('company/shift-duty-report', payload);
  }
  getshiftDutyExport(payload: any) {
    return this.webService.post('company/shift-duty-report-export', payload);
  }
  getshiftEarningExport(payload: any) {
    return this.webService.post('company/shift-earning-report-export', payload);
  }
  saveEncash(payload: any) {
    return this.webService.post('company/leave/update-leave-balance', payload);
  }

  applyLayoffListing(payload: any) {
    return this.webService.post('company/get-apply-layoff', payload);
  }
  getLayoffReport(payload:any){
    return this.webService.post('company/get-layoff-report' , payload)
  }
  updateLayoffData(payload: any) {
    return this.webService.post('company/update-layoff-data', payload);
  }

  attendanceReport(payload: any) {
    return this.webService.post('company/get-report-attendance-data', payload);
  }
  formjleaveReporrt(payload: any) {
    return this.webService.post('company/get-register-leave-data', payload);
  }
  formfifteenleaveReport(payload: any) {
    return this.webService.post('company/register-per-calender-year-report', payload);
  }
  earnedleaveReporrt(payload: any) {
    return this.webService.post('company/get-leave-ledgerl-report', payload);
  }
  printattendReport(payload: any) {
    return this.webService.post('company/get-employee-attendance-data-report', payload);
  }
  leaveledgerReport(payload: any) {
    return this.webService.post('company/get-leave-ledgerl-report', payload);
  }
  getprocessPayput(payload: any) {
    return this.webService.post('company/leave/process-payout-data', payload);
  }
  leavebankInstruction(payload: any) {
    return this.webService.post('company/generate-bank-instruction', payload);
  }
  exportearnleaveReport(payload: any) {
    return this.webService.post('company/employee-earned-leave-report-export', payload);
  }
  exportformJReport(payload: any) {
    return this.webService.post('company/get-register-leave-data-export', payload);
  }
  exportform15Report(payload: any) {
    return this.webService.post('company/register-per-calender-year-report-export', payload);
  }
  getformePdf(payload: any) {
    return this.webService.post('company/earning-certificate-export-pdf', payload);
  }
  getformeReport(payload: any) {
    return this.webService.post('company/form-e-rest-leave-calender-year-report', payload);
  }
  getprocessEmployee(payload: any) {
    return this.webService.post('company/leave/get-checked-employee', payload);
  }

  sendPayslipData(payload: any) {
    return this.webService.post('company/send-payslip-data', payload);
  }
  sendPayslipDataBulk(payload: any) {
    return this.webService.post('company/send-payslip-bulk', payload);
  }

  getChallanEmployeeList(payload: any) {
    return this.webService.post('company/get-challan-emp-list', payload);
  }
  generateChallanData(payload: any){
    return this.webService.post('company/generate-challan-data', payload )
  }
  getChallanData(payload:any){
    return this.webService.post('company/get-challan-data', payload)
  }
  employeeBlukJoinReport(payload:any){
    return this.webService.post('company/employee-bluk-joining-report', payload)
  }
  employeeBlukExitReport(payload:any){
    return this.webService.post('company/employee-exit-bulk-report', payload)
  }
  employeeJoinExcelDownload(payload:any){
    return this.webService.post('company/export-employee-bluk-joining-report', payload)
  }
  employeeExitExcelDownload(payload:any){
    return this.webService.post('company/export-exit-bluk-employee-joining-report', payload)
  }
  downloadAttendanceReportExcel(payload:any){
    return this.webService.post('company/attendance-report-data-excel', payload)
  }
  incentiveReportListing(payload:any){
    return this.webService.post('company/employee-incentive-report', payload)
  }

  //PT, PF, Esic Report in compliance
  employeeReportListing(payload:any){
    return this.webService.post('company/get-compliance-report-emp-list', payload)
  }
  //PT, PF, Esic Report Download in compliance
  employeeReportListingExcelDownload(payload:any){
    return this.webService.post('company/get-compliance-report-export-file', payload)
  }
  approvextraEarning(payload: any) {
    return this.webService.post('company/approve-extra-earning', payload);
  }
  runReimbursement(payload: any) {
    return this.webService.post('company/run-reimbursement-sheet', payload);
  }
  runextraEarning(payload: any) {
    return this.webService.post('company/run-extra-earning-sheet', payload);
  }
  approveAdvance(payload: any) {
    return this.webService.post('company/employee-advance-status-change', payload);
  }
  fetchGratuityFormL(payload:any){
    console.log('call grat');
    
    return this.webService.post('company/get-gratuity-form-report', payload); 
  }
  approvLeave(payload: any) {
    return this.webService.post('company/employee-leave-status-change', payload);
  }
  getSalaryTemplateHeadsLibrary(payload:any){
    return this.webService.post('company/get-salary-template-head-library', payload);
  }
  updateEmployeeKpiDetail(payload:any){
    return this.webService.post('company/update-employee-kpi-details', payload);
  }
  overtimeReportTwentythree(payload: any) {
    return this.webService.post('company/get-register-overtime-data', payload);
  }
  leaveencashBank(payload: any) {
    return this.webService.post('company/get-report-leave-encashment-data', payload);
  }
  overtimecustomReport(payload: any) {
    return this.webService.post('company/get-overtime-report-temp-wise-listing', payload);
  }
  overtimecustomReportExport(payload: any) {
    return this.webService.post('company/get-overtime-report-temp-wise-export-listing', payload);
  }

  getAppraisalListing(payload:any){
    return this.webService.post('company/get_employee_appraisal', payload);
  }
  addEmployeeAppraisal(payload:any){
    return this.webService.post('company/add_employee_appraisal', payload);
  }
  getAppraisalReportListing(payload:any){
    return this.webService.post('company/employee_appraisal_report', payload);
  }
  exportAppraisalReportExcel(payload:any){
    return this.webService.post('company/appraisal_report_excel_export', payload);
  }

  generateEmployeeInviteLink(payload:any){
    return this.webService.post('company/generate_employee_invite_link', payload)
  }
  ValidInvitationLink(payload:any){
    return this.webService.post('public/check_invitation_link', payload)
  }

  createEmployeePublic(payload: any) {
    return this.webService.postFormData('public/create-employee', payload);
  }
  updateEmployeePublic(payload: any) {
    return this.webService.postFormData(
      'public/update-employee',
      payload
    );
  }
  updateEmployeeAddressPublic(payload: any) {
    return this.webService.postFormData(
      'public/update-employee-address',
      payload
    );
  }
  updateEmployeeBankDetailsPublic(payload: any) {
    return this.webService.postFormData(
      'public/update-employee-bank',
      payload
    );
  }
  fetchEmployeeFormAListing(payload:any){
    return this.webService.post('company/get-employee-master-roll-form-a-report', payload); 
  }
  fetchEmployeeSalaryCerti(payload:any){
    return this.webService.post('company/earning-certificate-export-pdf', payload); 
  }
  fetchStatesPublic() {
    return this.webService.post('public/get_state_list', {
      countrycode: 'IN',
    });
  }
  getAdvanceListing(payload:any){
    return this.webService.postFormData(
      'company/get-advance-listing',
      payload
    );
  }
  exportAdvanceReportExcel(payload:any){
    return this.webService.post('company/advance_report_excel_export', payload);
  }
  generateAdvanceBankInstruction(payload: any) {
    return this.webService.postFormData(
      'company/generate-advance-bank-instruction',
      payload
    );
  }
  employeeFullAndFinalReport(payload:any){
    return this.webService.postFormData(
      'company/employee-full-and-final-report',
      payload
    )
  }
  fetchPtaxReturnData(payload:any)  {
    return this.webService.post('company/get-pt-return-report', payload); 
  }
  getSummaryReportData(payload:any){
    return this.webService.postFormData(
      'company/get-summary-report',
      payload
    )
  }
  getVarianceReportData(payload:any){
    return this.webService.postFormData(
      'company/get-variance-report',
      payload
    )
  }

  //dashboard data 
 
  fetchWagesFormB(payload:any){
    return this.webService.post('company/get-employee-form-b-register-report', payload); 
  }
  fetchForm37And7A(payload:any){
    return this.webService.post('company/get-esic-form-export', payload); 
  }


  // generatedBonusSlipsExport(payload:any){
  //   return this.webService.postFormData('company/employee-bonus-report', payload)
  // }
  getSystemDocumentList(payload: any) {
    return this.webService.post('company/employee-file-document-list', payload);
  }
  getUserDocumentList(payload: any) {
    return this.webService.post('company/get-document-master', payload);
  }
  deleteDocument(payload: any) {
    return this.webService.post('company/delete-document-vault', payload);
  }
  getDocumentDetails(payload: any) {
    return this.webService.post('company/employee-file-document-list-details', payload);
  }
  renameDocument(payload: any) {
    return this.webService.post('company/edit-document-vault', payload);
  }
  downloadDocument(payload: any) {
    return this.webService.post('company/download-document-vault', payload);
  }
  addDocumentToVault(payload: any) {
    return this.webService.postFormData('company/add-document-to-vault', payload);
  }
  listCompanyLocation(payload:any){
    return this.webService.postFormData('company/list-company-location', payload)
  }
  // updateCompanyLocation(payload:any){
    // this.createCompanyLocation(payload).toPromise();
  // };
  changeLocationStatus(payload:any){
    return this.webService.postFormData('company/company-status-change', payload)
  };
  deleteCompanyLocation(payload:any){
    return this.webService.postFormData('company/company-delete', payload)
  }

  
  fetchCreditUsageView(payload: any) {
    return this.webService.post( 'company/get-company-credit-usage-details-list',
      payload
    );
  }




  downloadBufferFile(url:string, payload?:Object){
  return this.webService.post('company/' + url, payload)
  }

  saveFile(data: ArrayBuffer): void {
    const blob = new Blob([new Uint8Array(data)], { type: 'image/png' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'yourFileName'; 
    // Append the anchor element to the document
    document.body.appendChild(link);

    link.click();

    // Remove the anchor element from the document
    document.body.removeChild(link);
  }
 
  fetchEmployeeBulkUpload(payload:any){
    return this.webService.post('company/employee-bulk-upload', payload); 
  }
  getEmployeeFileUploadStatus(payload:any){
    return this.webService.post('company/employee-file-upload-status', payload); 
  }
}
