


<div class="info-fluid height100 m-4">
  <div class="col-md-12 hrmlogo align-items-center">
       <img class="static-text" src="assets/images/logo.png" alt="logo">
    </div>
    <div class="container mb-4">
        <div class="taxformmain" *ngIf="isLinkValid && !isFormSubmit && this.empDetails?.offer_letter_sent == 'yes'">
          
          
          <div class="content" >
            <div class="boxshodo borderredius-0">
                <div class="p-15 py-4 border-bottom">
                  <div class="header text-center mb-4">
                    <h4>OFFER LETTER</h4>
                   
                  </div>
                  <br>
                  <div  class="d-flex justify-content-center">
                    
                  <ol>
                    <li class="mb-4 ms-4">
                      <div  class="cursor-pointer" >
                      <ng-container *ngIf="company_logo; else noComlogo">
                          <div class="profileimg" >
                              <img [src]="company_logo"  alt="user">
                          </div>
                      </ng-container>
                      <ng-template #noComlogo >
                          <div class="profileimg">
                              <img src="assets/images/user.jpg"  alt="user">
                          </div>
                      </ng-template>
                      </div>
                    </li>
                      <li><h5>{{company_name}}</h5></li>
                      <li>{{com_address}}</li>
                      <p>{{date}}</p>
    
                      <p>
                        <li><b>{{emp_name}}</b></li>
                        <li>{{emp_address}}</li>
                      </p>
                  
    
                    
                      <p>Dear {{emp_name}},</p>
                      <li>We are pleased to offer you the position of <b> {{job_title}}</b> at <b>{{company_name}}</b>, as discussed during your interview.</li>
                        <li> We believe your skills and experience make you an ideal fit for our team and contribute to the company's growth.</li>
                    
                    <br>
                    
                      <p><b>Below are the details of the offer: </b></p>
                      <ul>
                        <li><span >Job Title</span>: {{job_title}} </li>
                        <li><span >Department</span>: {{department}}</li>
                         <li><span >Expected Joining Date</span>: {{expected_date}} </li>
                        <li><span ></span>Salary: {{salary}} &nbsp; AED (Per Annum)</li>
                        <p><span >Benefits</span>:</p>
                        <ng-container *ngFor="let data of benefits">
                        <li>-{{data}}</li>
                        </ng-container>
                      </ul>
                      <br>
                    
                 
                      <p>Please confirm your acceptance of this offer by signing and returning one copy of this letter to us by <b>{{deadline_date}} </b>. We also require a start date confirmation.
                      </p>
                      <li>If you have any questions or concerns, please do not hesitate to contact us.</li>
                      <li>Congratulations on your new role, and we look forward to welcoming you to {{company_name}}!</li>
                  
    
                    <li>
                      <p>Sincerely,</p>
    
                      <p>{{hr_name}} </p>
                      <p>HR</p>
                      <p>{{company_name}}</p>
                    </li>
                    <form [formGroup]="acceptanceForm">
                      <h5>Acceptance:</h5>
                      <p>I, <b>{{emp_name}}</b>, accept the offer of employment with <b>{{company_name}}</b> as outlined above. I confirm my start date as {{expected_date}}.</p>
                      
                      <h5 style="color: rgb(53, 53, 131);">Choose either of the options to submit your signature.</h5>
                      
                      <p>Signature:</p>
                    
                      <!-- Signature Pad Container -->
                      <div class="signature-container" style="position: relative;">
                        <!-- Signature Pad -->
                        <signature-pad 
                          [options]="signaturePadOptions" 
                          (onEndEvent)="drawComplete()"
                          #signaturePad>
                        </signature-pad>
                    
                        <!-- Overlay to disable the signature pad when isSignaturePadEnabled is false -->
                        <div 
                          *ngIf="!isSignaturePadEnabled" 
                          class="signature-overlay" 
                          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255,0.7);">
                        </div>
                    
                        <span style="cursor: pointer;" (click)="clearPad()">Clear</span>
                      </div>
                    
                      <br>
                    
                      <!-- Hidden input for the signature field -->
                      <input type="text" formControlName="signature" class="form-control" hidden
                        [ngClass]="{'is-invalid': acceptanceForm.get('signature')?.hasError('required') && acceptanceForm.get('signature')?.touched}"
                        [disabled]="!isSignaturePadEnabled">
                    
                      <span class="invalid-feedback" *ngIf="acceptanceForm.get('signature')?.hasError('required') && acceptanceForm.get('signature')?.touched" style="font-size: 15px;">
                        Either Signature OR Signature Image Is required.
                      </span>
                    
                      <p>
                        Upload Signature Image: 
                        <input type="file" accept=".jpeg, .jpg, .png, .pdf" (change)="onSignatureImageChange($event)" class="form-control" 
                          [ngClass]="{'is-invalid': acceptanceForm.get('signatureImage')?.hasError('required') && acceptanceForm.get('signatureImage')?.touched}"
                          [disabled]="!isFileUploadEnabled">
                        <span class="invalid-feedback" *ngIf="acceptanceForm.get('signatureImage')?.hasError('required') && acceptanceForm.get('signatureImage')?.touched">
                          Signature image is required.
                        </span>
                      </p>
                    </form>
                    
                <p>Date: {{date}}</p>
                  </ol>
                  </div>
                  
            </div>
         </div>
       </div>
    
       <div class="row  mt-2" >
        <div class="col-12 col-sm-12">
          
          <button class="btn button-solide" (click)="acceptOffer(true, $event)">
            <span> ACCEPT</span>
          </button>&nbsp;&nbsp;
    
          <button class="btn button-solide" (click)="acceptOffer(false, $event)" >
            <span>REJECT</span>
          </button>
        </div>
      </div>
    </div>
    
    </div>
    </div>
    <div *ngIf="!isLinkValid && !isLoading" class="text-center">
        <h2 class="mt-5 pt-5">This Invite link is Expired!</h2>
      </div>
      <div *ngIf="isFormSubmit == 'yes'" class="text-center">
        <h2 class="mt-5 pt-5">Thanks For Your Response! Will Get Back To You Soon!</h2>
      </div>
      <div *ngIf="isValid == 'inactive'" class="text-center">
        <h2 class="mt-5 pt-5">You Already Have Submitted Response!</h2>
      </div>
     
      <div *ngIf="this.empDetails?.offer_letter_sent == 'no'" class="text-center">
        <h2 class="mt-5 pt-5">The Requested Link Is Not Valid!</h2>
      </div>
    
    
    