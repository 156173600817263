import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SignaturePad } from 'angular-signature-pad-v2';
import { EmployeeProfileService } from 'src/app/services/employee-profile.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import * as Global from 'src/app/shared/globals';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-acceptance-form',
  templateUrl: './acceptance-form.component.html',
  styleUrls: ['./acceptance-form.component.css']
})
export class AcceptanceFormComponent {
  // @ViewChild('signatureCanvas',{static:true}) canvas!:HTMLCanvasElement ;
  // signaturePad!: SignaturePad;
  // signatureData!: string | null;
  // canvas!: HTMLCanvasElement | null ;
  // ctx!: CanvasRenderingContext2D | null ;

  @ViewChild(SignaturePad) signaturePad!: SignaturePad;
   signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 2,
    'canvasWidth': 500,
    'canvasHeight': 300
  };
  Global = Global;

empDetails!:any;

emp_id!:string;
company_name!:string; 
emp_name!:string; 
emp_address!:string; 
com_address!:string;
expected_date!:any; 
salary!:string; 
department!:string; 
date!:string; 
hr_name!:string; 
job_title!:string; 
deadline_date!:string; 
benefits!:any[];

currentDate!: string;
lang: any = "en";
currentLanguage$?: Subscription;
translations: any;
acceptanceForm!: FormGroup;
acceptance_id:any;
isLoading: boolean = true;
isLinkValid: boolean = false;
isFormSubmit:any;

userData:any;
selectedSignatureImage!: string;
companyData: any;
employee_id: any;
isValid: any;
isSignaturePadEnabled = true;
isFileUploadEnabled = true;
company_logo!:any;


 constructor( 
  private route: ActivatedRoute,
  private empProfileService: EmployeeProfileService,
  private langService: LanguageTranslateService,
  private toastr: ToastrService,
  private router: Router,
  private formBuilder: FormBuilder,
  private activatedRoute: ActivatedRoute,
  private elementRef: ElementRef,
  private datePipe: DatePipe,
  private spinner: NgxSpinnerService,
  private authService: AuthService
){
  this.spinner.show();
  this.isLoading = true;
  this.acceptance_id = this.activatedRoute.snapshot.queryParams['id'];
  this.acceptanceForm = this.formBuilder.group({
    signature:[null, Validators.compose([Validators.required])],
    // signatureImage: [null, Validators.required]
  })
console.log(this.isFormSubmit,'ujm8uj8uj89')
console.log('ujm8uj8uj89')

this.authService.com_logo$.subscribe((img:any)=>{
  this.company_logo = img;        
});

  this.empProfileService.checkOnboardAcceptanceLink({
    invitation_id: this.acceptance_id
  }).then((res)=>{
    if(res?.status == 'success' && res?.doc){
      let data = res?.doc;
      this.userData = data;
      this.isLinkValid = true;
      this.isLoading = false;
      console.log(this.userData)
      console.log(this.userData?.onboard_id)

    }else if(res?.status == 'success' && res?.doc == null){
      console.log('hhh')
      this.isValid = 'inactive'
    }
    this.fetchEmployeeData();
  })
  
  
}

ngAfterViewInit() {
  setTimeout(() => {
    if (this.signaturePad) {
      this.signaturePad.clear();
      this.signaturePad.set('minWidth', 0.5);
    }
  }, 0);
}

drawComplete() {
  if (this.signaturePad) {
    this.isFileUploadEnabled = false;
    const signatureDataUrl = this.signaturePad.toDataURL();
    console.log(signatureDataUrl);
    this.acceptanceForm.patchValue({ signature: signatureDataUrl });
  } else {
    console.error('SignaturePad is not defined');
  }
}

drawStart() {
  console.log('begin drawing');
}

clearPad() {
  if (this.signaturePad) {
    this.signaturePad.clear();
    this.acceptanceForm.patchValue({ signature: null });
    this.isFileUploadEnabled = true;
  } else {
    console.error('SignaturePad is not defined');
  }
}



ngOnInit(): void {
  const date = new Date();
  this.currentDate = this.formatDate(date);
  this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
    this.lang = this.langService.getLangLocalStorage();
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('profile', 'PreviewOfferLetterComponent')?.then((res: any) => {
          this.translations = res;
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });

    let comData:any=(localStorage.getItem('hrmlix-uae-companyuser-details'));
    this.companyData = JSON.parse(comData);
    console.log(this.companyData)

  
 

 
}
formatDate(date: Date): string {
  const day = String(date.getDate()).padStart(2, '0'); 
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const year = date.getFullYear();

  return `${day}-${month}-${year}`; 
}

fetchEmployeeData(){
  this.empProfileService.getAcceptanceData({
    onboard_id: this.userData?.onboard_id
  }).then((res)=>{
    console.log(res)
   
    if(res?.status === 'success'){
      const data = res?.data[0];
      this.empDetails = data;
      this.spinner.hide();
      console.log(this.empDetails)
      console.log(this.currentDate)

  // this.emp_id = this.userData?.onboard_details[0]?.onboard_id;
  this.company_name = this.companyData?.details?.establishment_name;
  this.emp_name = (this.empDetails?.emp_first_name + ' ' + this.empDetails?.emp_last_name);
  this.emp_address = [
    this.empDetails?.emp_address?.locality ?? '',
    this.empDetails?.emp_address?.city ?? '',
    this.empDetails?.emp_address?.pincode ?? ''
  ].filter(Boolean).join(', '),
  this.com_address =  [
           
    this.companyData?.communication_office_address?.locality ?? '',
    this.companyData?.communication_office_address?.state ?? '',
    this.companyData?.communication_office_address?.pin_code ?? '',
   

  ].filter(Boolean).join(','),
  this.expected_date = this.datePipe.transform(
    this.empDetails?.employment_hr_details
      ?.date_of_join,
    'yyyy-MM-dd' ?? 'N/A');
  this.salary = this.empDetails?.employment_hr_details?.gross_salary;
  this.department = this.empDetails?.department_name;
  this.date = this.currentDate;
  this.hr_name = this.empDetails?.hr_name;
  this.job_title = this.empDetails?.employment_hr_details?.job_title;
  this.deadline_date = this.empDetails?.employment_hr_details?.deadline_date ?  moment(this.empDetails?.employment_hr_details?.deadline_date).format('YYYY-MM-DD') : "N/A";

  let benefitsArray: any[] = this.empDetails?.employment_hr_details?.benefits || [];
  console.log(benefitsArray)


  if (benefitsArray.length > 0) {
    console.log('Benefits array:', benefitsArray);
  
    this.benefits = benefitsArray.map((item) => {
      console.log('Item:', item); 
      console.log('Benefits type:', item?.benefits_types);
      return item?.benefits_types; 
    });
  
    console.log('Mapped benefits:', this.benefits);
  } else {
    console.log('Benefits array is empty or undefined');
  }
    }
  })
  }

 

// onSignatureImageChange(event: any) {
//   const file = event.target.files[0];

//   // Limit file size to 5MB
//   if (file && file.size <= 5 * 1024 * 1024) { 
//     const reader = new FileReader();

//     reader.onload = (e: any) => {
//       // const base64Image = e.target.result;
//       const base64Image = e.target.result.split(',')[1];
//       this.selectedSignatureImage = base64Image;
//       console.log(this.selectedSignatureImage);
//       this.acceptanceForm.patchValue({ signature: base64Image });
//     };

//     reader.readAsDataURL(file);
//   } else {
//     console.error('File is too large');
//     // Optionally, you can show a user notification here
//   }
// }

onSignatureImageChange(event: any) {
  const file = event.target.files[0];
  console.log(file)
  if (file && file.size <= 5 * 1024 * 1024) {
    
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        // Compress the image using a canvas
        const canvas = document.createElement('canvas');
        const maxWidth = 500; // Set the maximum width you want to resize to
        const scaleSize = maxWidth / img.width;
        canvas.width = maxWidth;
        canvas.height = img.height * scaleSize;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Convert the canvas to a base64 string
        const compressedBase64Image = canvas.toDataURL('image/jpeg', 0.7); // 0.7 is the compression quality

        // Remove the base64 prefix (data:image/jpeg;base64,)
        const base64Image = compressedBase64Image.split(',')[1];

        this.selectedSignatureImage = base64Image;
        this.acceptanceForm.patchValue({ signature: base64Image });
        this.isSignaturePadEnabled = false; // Disable the signature pad once an image is uploaded
      };
    };

    reader.readAsDataURL(file);
  } else if(file && file.size > 5 * 1024 * 1024) {
    alert('File size is too large. Please upload a file smaller than 5MB.');
  }else if(file == undefined){
    this.isSignaturePadEnabled = true;
  }
}




acceptOffer(flag: boolean, event:any) {
  if (this.acceptanceForm.invalid) {
    this.acceptanceForm.markAllAsTouched();
    return;
  }

  const payload = {
    signature: (this.acceptanceForm.value.signature || this.selectedSignatureImage),
    status: flag === true ? 'approved' : 'rejected',
    onboard_id: this.userData?.onboard_id,
    corporate_id: this.userData?.corporate_id,
    // signature_image: this.selectedSignatureImage
  };
  const button = event.currentTarget as HTMLButtonElement;
  button.classList.add('btn-loading');

  this.empProfileService.acceptOfferLetter(payload).then((res) => {
    console.log(res)
    if (res?.status === 'success' && res?.message === "Your response sent successfully") {
      console.log('yyyy')
      button.classList.remove('btn-loading');
      this.isFormSubmit = 'yes';
     
    }
    else if(res?.status === 'success' && res?.message === "Your Deadline date has expired"){
      console.log('nnnn')
      button.classList.remove('btn-loading');
       this.isFormSubmit = 'no';
      
    }
    else{
      button.classList.remove('btn-loading');
      this.toastr.error(Global.showServerErrorMessage(res?.message));
    }
  });
}
}

// ngAfterViewInit(): void {
 
//   setTimeout(() => {
//     const canvas = this.signatureCanvas?.nativeElement;
//     console.log('Canvas:', canvas);  
//     if (canvas) {
//       this.signaturePad = new SignaturePad(canvas, {
//         backgroundColor: 'rgb(255, 255, 255)',  
//         penColor: 'rgb(0, 0, 0)',  
//       });
//     } else {
//       console.error('Canvas not found');
//     }
//   }, 0);
// }

// clearSignature(): void {
//   if (this.signaturePad) {
//     this.signaturePad.clear();
//   }
// }

// drawOnCanvas(): void {
//   const canvas = this.signatureCanvas?.nativeElement;
//   const ctx = canvas?.getContext('2d');
//   if (ctx) {
//     ctx.beginPath();
//     ctx.moveTo(50, 50);
//     ctx.lineTo(200, 200);
//     ctx.stroke();
//   }
// }

// saveSignature(): void {
//   if (!this.signaturePad.isEmpty()) {
//     const signatureDataUrl = this.signaturePad.toDataURL();  // Base64 image data
//     this.acceptanceForm.patchValue({ signature: signatureDataUrl });
//   } else {
//     console.log('Signature pad is empty');
//   }
// }


// acceptOffer(flag: boolean): void {
  
//    this.saveSignature();

//   if (this.acceptanceForm.invalid) {
//     this.acceptanceForm.markAllAsTouched();
//     return;
//   }
//   console.log({
//     signature: this.acceptanceForm.value.signature,
//     status: flag === true ? 'approved' : 'rejected',
    
//   });

//   // this.empProfileService.acceptOfferLetter({
//   //   signature: this.acceptanceForm.value.signature,  // Base64 encoded signature image
//   //   status: flag === true ? 'approved' : 'rejected',
//   //   onboard_id: this.userData?.onboard_id,
//   //   corporate_id: this.userData?.corporate_id
//   // }).then((res) => {
//   //   if (res?.status === 'success') {
//   //     this.isFormSubmit = true;
//   //   }
//   // });
// }



