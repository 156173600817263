import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AttendanceComponent } from './pages/attendance/attendance.component';
import { PayrollsComponent } from './pages/payrolls/payrolls.component';
import { AuthGuard } from './core/guards/auth.guard';
import { CompanyuserGuard } from './core/guards/companyuser.guard';
import { NotfoundPageComponent } from './pages/notfound-page/notfound-page.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { LeaveComponent } from './pages/leave/leave.component';
import { ApprovalComponent } from './pages/approval/approval.component';
import { HomeComponent } from './pages/home/home.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ShiftComponent } from './pages/shift/shift.component';
import { AnnouncementListComponent } from './pages/miscellaneous/announcement-list/announcement-list.component';
import { NoticeListComponent } from './pages/miscellaneous/notice-list/notice-list.component';
import { AnnouncementComponent } from './pages/announcement/announcement.component';
import { SalaryRevisionComponent } from './pages/salary-revision/salary-revision.component';
import { BillingsComponent } from './pages/billings/billings.component';
import { AnnouncementDetailsComponent } from './pages/miscellaneous/announcement-details/announcement-details.component';
import { InviteEmployeeComponent } from './pages/invite-employee/invite-employee.component';
import { VaultComponent } from './pages/vault/vault.component';
import { AcceptanceFormComponent } from './pages/acceptance-form/acceptance-form.component';
import { AtsComponent } from './pages/ats/ats.component';

const redirectToLanguage = (): string => {
  let selectedLanguage: string = localStorage.getItem('lang') ?? 'en';
  if (selectedLanguage === 'en' || selectedLanguage === 'ar') return `/${selectedLanguage}/dashboard`;
  else selectedLanguage = 'en';
  localStorage.setItem('lang', selectedLanguage);
  return '/en/dashboard';
}

const routes: Routes = [
  { path: '', redirectTo: redirectToLanguage(), pathMatch: 'full' },
  {
    path: '', loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':lang/dashboard',
    component: HomeComponent,
    canActivate: [CompanyuserGuard],
    data: { pageEngTitle: 'Dashboard', pageArTitle: 'لوحة التحكم' }
  },
  {
    path: ':lang/announcement-list',
    component: AnnouncementListComponent,
    canActivate: [CompanyuserGuard]
    // data: { pageTitle: 'Dashboard' },
  },
  {
    path: ':lang/announcement-detail/:annc_id',
    component: AnnouncementDetailsComponent,
    canActivate: [CompanyuserGuard]
    // data: { pageTitle: 'Dashboard' },
  },
  {
    path: ':lang/notice',
    component: NoticeListComponent,
    // data: { pageTitle: 'Dashboard' },
    canActivate: [CompanyuserGuard]
  },

  {
    path: ':lang/attendance',
    component: AttendanceComponent,
    loadChildren: () => import('./pages/attendance/attendance.module').then((m) => m.AttendanceModule),
    canActivate: [CompanyuserGuard]
  },
  {
    path: ':lang/payrolls',
    component: PayrollsComponent,
    loadChildren: () => import('./pages/payrolls/payrolls.module').then((m) => m.PayrollsModule),
    canActivate: [CompanyuserGuard]
  },
  {
    path: ':lang/salary-revision',
    component: SalaryRevisionComponent,
    loadChildren: () => import('./pages/salary-revision/salary-revision.module').then((m) => m.SalaryRevisionModule),
    canActivate: [CompanyuserGuard]
  },
  {
    path: ':lang/shift',
    component: ShiftComponent,
    loadChildren: () => import('./pages/shift/shift.module').then((m) => m.ShiftModule),
    canActivate: [CompanyuserGuard]
  },

  {
    path: ':lang/leave',
    component: LeaveComponent,
    loadChildren: () => import('./pages/leave/leave.module').then((m) => m.LeaveModule),
    canActivate: [CompanyuserGuard]

  },
  {
    path: ':lang/employees',
    component: ProfileComponent,
    loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [CompanyuserGuard]
  },

  {
    path: ':lang/approval',
    component: ApprovalComponent,
    loadChildren: () => import('./pages/approval/approval.module').then((m) => m.ApprovalModule),
    canActivate: [CompanyuserGuard]

  },
  {
    path: ':lang/appraisal',
    loadChildren: () => import('./pages/appraisal/appraisal.module').then((m) => m.AppraisalModule),
    canActivate: [CompanyuserGuard]

  },
  {
    path: ':lang/announcement',
    component: AnnouncementComponent,
    loadChildren: () => import('./pages/announcement/announcement.module').then((m) => m.AnnouncementModule),
    canActivate: [CompanyuserGuard]

  },



  {
    path: ':lang/reports',
    loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReportsModule)
  },

  {
    path: ':lang/billings',
    component: BillingsComponent,
    loadChildren: () => import('./pages/billings/billings.module').then((m) => m.BillingsModule),
    canActivate: [CompanyuserGuard]

  },
  {
    path: ':lang/settings',
    component: SettingsComponent,
    loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [CompanyuserGuard]
  },

  {
    path: ':lang/vault',
    component: VaultComponent,
    loadChildren: () => import('./pages/vault/vault.module').then((m) => m.VaultModule),
    canActivate: [CompanyuserGuard]
  },
  {
    path: ':lang/invitation-form',
    component: InviteEmployeeComponent,
    loadChildren: () => import('./pages/invite-employee/invite-employee.module').then((m) => m.InviteEmployeeModule),
    canActivate: [CompanyuserGuard]
  },

  {
    path: ':lang/acceptance-onboard-form',
    component: AcceptanceFormComponent,
    loadChildren: () => import('./pages/acceptance-form/acceptance-form.module').then((m) => m.AcceptanceFormModule),
    canActivate: [CompanyuserGuard]
  },
  {
    path: ':lang/ats',
    component: AtsComponent,
    loadChildren: () => import('./pages/ats/ats.module').then((m) => m.AtsModule),
    canActivate: [CompanyuserGuard]
  },

  { path: "**", component: NotfoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

